import { START_PURCHASE, END_PURCHASE } from '../actions';

function isDoingPurchase(state = false, action) {
    let newState;

    switch (action.type) {
        case START_PURCHASE:
            newState = true;
            break;

        case END_PURCHASE:
            newState = false;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default isDoingPurchase;
