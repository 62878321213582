import { START_PURCHASE, END_PURCHASE } from '../simpleActions';
import { StoreService } from '../../../services';

function doPurchase(payload) {
    return (dispatch) => {
        dispatch(START_PURCHASE);
        return StoreService.doPurchase(payload)
            .finally(() => dispatch(END_PURCHASE));
    };
}

export { doPurchase };
