import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import Modal from '../../components/Modal';
import CartItem from '../../components/CartItem';
import { routes, formatPrice, config } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    cart: object.isRequired,
};

function CartList({ cart }) {
    const cartItems = Object.values(cart).map(
        itemData => <CartItem itemData={itemData} removable key={itemData.id} />,
    );

    let warningMessage;
    let checkoutButton;
    if (cartItems.length === 0) {
        warningMessage = (
            <div className={styles.warning}>
                Não há itens no seu carrinho de compras
            </div>
        );
        checkoutButton = null;
    } else {
        warningMessage = null;
        checkoutButton = (
            <Link to={routes.checkout()} replace className={styles.checkoutButton}>
                Finalizar
            </Link>
        );
    }

    let totalPrice = null;
    if (config.showPrices && cartItems.length > 0) {
        totalPrice = (
            <div className={styles.totalPrice}>
                Preço total:
                {' '}
                {formatPrice(Object.values(cart)
                    .map(c => c.price * c.count)
                    .reduce((x, y) => x + y, 0))
                }
            </div>
        );
    }

    return (
        <Modal>
            <div className={styles.title}>
                Pedidos
            </div>
            <div className={styles.cartList}>
                {warningMessage}
                {cartItems}
                {totalPrice}
                <Link to={routes.home()} className={styles.homeButton}>
                    + Adicionar mais itens.
                </Link>
                {checkoutButton}
            </div>
        </Modal>
    );
}

CartList.propTypes = propTypes;

export default CartList;
