import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { object, bool, func } from 'prop-types';
import CheckoutPage from '../../components/CheckoutPage';
import ContentBox from '../../components/ContentBox';
import CartItem from '../../components/CartItem';
import LoadingSpinner from '../../components/LoadingSpinner';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    cart: object.isRequired,
    isDoingPurchase: bool.isRequired,
    doPurchase: func.isRequired,
    clearCart: func.isRequired,
    openPurchaseResult: func.isRequired,
};

class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: null,
            soldOutItems: null,
        };

        this.setError = this.setError.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    setField(field, value) {
        this.setState({ [field]: value });
    }

    setError(error = null) {
        this.setState({ error });
    }

    setSoldOutItems(soldOutItems = null) {
        this.setState({ soldOutItems });
    }

    submitForm(e = null, forcePurchase = false) {
        if (e) {
            e.preventDefault();
        }

        const {
            doPurchase,
            cart,
            clearCart,
            openPurchaseResult,
        } = this.props;
        const { email, password } = this.state;

        // Clears the error message
        this.setError(null);
        this.setSoldOutItems(null);

        // Builds the payload
        const payload = new FormData();
        payload.append('email', email);
        payload.append('password', password);
        payload.append('force_purchase', forcePurchase);
        Object.values(cart).forEach((itemData) => {
            for (let i = 0; i < itemData.count; i++) {
                payload.append('items', itemData.id);
            }
        });

        doPurchase(payload).then((result) => {
            if (result.success) {
                clearCart();
                openPurchaseResult();
            } else if (result.payload && result.payload.soldOutItems) {
                this.setSoldOutItems(result.payload.soldOutItems);
            } else {
                this.setError(result.message);
            }
        });
    }

    render() {
        const { cart, isDoingPurchase } = this.props;
        const {
            email,
            password,
            error,
            soldOutItems,
        } = this.state;

        const cartItems = Object.values(cart).map(
            itemData => <CartItem itemData={itemData} removable key={itemData.id} />,
        );

        if (cartItems.length === 0) {
            return <Redirect to={routes.cart()} replace />;
        }

        let warningMessage;
        if (cartItems.length === 0) {
            warningMessage = (
                <div className={styles.warning}>
                    Não há itens no seu carrinho de compras
                </div>
            );
        } else {
            warningMessage = null;
        }

        let errorMessage;
        if (error) {
            errorMessage = (
                <div className={styles.error}>
                    {error}
                </div>
            );
        } else {
            errorMessage = null;
        }

        let submitButton;
        if (isDoingPurchase || cartItems.length === 0) {
            submitButton = null;
        } else {
            submitButton = (
                <a className={styles.submit} onClick={this.submitForm}>
                    Finalizar
                </a>
            );
        }

        let reviewOrder;
        if (isDoingPurchase) {
            reviewOrder = null;
        } else {
            reviewOrder = (
                <Link to={routes.cart()} replace className={styles.reviewButton}>
                    Revisar itens
                </Link>
            );
        }

        let loadingSpinner;
        if (isDoingPurchase) {
            loadingSpinner = <LoadingSpinner className={styles.loadingSpinner} />;
        } else {
            loadingSpinner = null;
        }

        const elements = [
            <CheckoutPage title="Finalizar compra" key="form">
                <form onSubmit={this.submitForm}>
                    <ContentBox title="Dados do Usuário">
                        <div className={styles.formFieldTitle}>
                            E-mail
                        </div>
                        <input
                            type="text"
                            value={email}
                            onChange={e => this.setField('email', e.target.value)}
                        />
                        <div className={styles.formFieldTitle}>
                            Senha
                        </div>
                        <input
                            type="password"
                            value={password}
                            onChange={e => this.setField('password', e.target.value)}
                        />
                    </ContentBox>
                    {errorMessage}
                    {loadingSpinner}
                    {reviewOrder}
                    {submitButton}
                </form>
            </CheckoutPage>,
        ];

        if (soldOutItems) {
            const soldOutNodes = soldOutItems.map(soldOutItem => (
                <div className={styles.soldOutItem} key={soldOutItem.name}>
                    <div className={styles.itemInfo}>
                        <div className={styles.name}>
                            {soldOutItem.name}
                        </div>
                        <div className={styles.quantity}>
                            Quantidade:
                            {' '}
                            {soldOutItem.quantity}
                        </div>
                    </div>
                    <div className={styles.deliveryTime}>
                        30 Dias para entrega
                    </div>
                </div>
            ));

            elements.push(
                <CheckoutPage
                    title="Os itens abaixo estão com um maior tempo de entrega. Você topa esperar ou quer rever seu pedido?"
                    key="confirmation"
                >
                    {soldOutNodes}
                    <div className={styles.buttons}>
                        <a className={styles.finishPurchase} onClick={() => this.submitForm(null, true)}>
                            Finalizar compra
                        </a>
                        <Link className={styles.reviewOrder} to={routes.cart()}>
                            Rever o seu pedido
                        </Link>
                    </div>
                </CheckoutPage>,
            );
        }

        return elements;
    }
}

Checkout.propTypes = propTypes;

export default Checkout;
