import randomInt from './randomInt';

let aux;
let pivot;

function shuffle(array) {
    const result = array.slice(0);

    for (let i = 0; i < array.length - 1; i++) {
        pivot = randomInt(i, array.length - 1);
        aux = result[i];
        result[i] = result[pivot];
        result[pivot] = aux;
    }

    return result;
}

export default shuffle;
