import { connect } from 'react-redux';
import { CLEAR_CART, doPurchase as doPurchaseAction } from '../../../redux/actions';
import Checkout from './Checkout';
import { routes } from '../../utils';

const mapStateToProps = ({ cart, isDoingPurchase }) => ({ cart, isDoingPurchase });

const mapDispatchToProps = (dispatch, { history }) => {
    const doPurchase = payload => dispatch(doPurchaseAction(payload));
    const clearCart = () => dispatch(CLEAR_CART);
    const openPurchaseResult = () => history.replace(routes.purchaseResult());

    return { doPurchase, clearCart, openPurchaseResult };
};

const CheckoutContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Checkout);

export default CheckoutContainer;
