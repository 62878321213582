import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { number, string } from 'prop-types';
import TweenMax from 'gsap/umd/TweenMax';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    count: number.isRequired,
    className: string,
};

const defaultProps = {
    className: null,
};

class CartCount extends Component {
    constructor(props) {
        super(props);

        this.rootNode = createRef();
        this.countTween = null;
    }

    componentDidUpdate(prevProps) {
        const { count } = this.props;

        if (count !== prevProps.count) {
            this.killTween();
            this.countTween = TweenMax.fromTo(
                this.rootNode.current,
                0.5,
                { scale: 1.5 },
                { scale: 1 },
            );
        }
    }

    componentWillUnmount() {
        this.killTween();
    }

    killTween() {
        if (this.countTween) {
            this.countTween.kill();
            this.countTween = null;
        }
    }

    render() {
        const { count, className } = this.props;

        return (
            <Link
                to={routes.cart()}
                className={[styles.cartCount, className].join(' ')}
            >
                <div ref={this.rootNode} className={styles.count}>
                    {count}
                </div>
            </Link>
        );
    }
}

CartCount.propTypes = propTypes;
CartCount.defaultProps = defaultProps;

export default CartCount;
