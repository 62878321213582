import { connect } from 'react-redux';
import Header from './Header';
import { scrollTo as scrollToAction } from '../../../redux/actions';

const mapStateToProps = ({ width, isMobile }) => ({ width, isMobile });

const mapDispatchToProps = (dispatch) => {
    const scrollTo = target => dispatch(scrollToAction(target));

    return { scrollTo };
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);

export default HeaderContainer;
