function deepClone(original) {
    if (original === null) {
        return null;
    }

    if (Array.isArray(original)) {
        return original.map(deepClone);
    }

    if (typeof original === 'object') {
        const clone = {};
        Object.keys(original).forEach((key) => { clone[key] = deepClone(original[key]); });
        return clone;
    }

    return original;
}

export default deepClone;
