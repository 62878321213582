import React, { Component } from 'react';
import { arrayOf, objectOf, string } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    photos: arrayOf(objectOf(string)).isRequired,
    className: string,
};

const defaultProps = {
    className: null,
};

class PhotoCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPhoto: 0,
        };

        this.changePhoto = this.changePhoto.bind(this);
    }

    changePhoto(selectedPhoto) {
        this.setState({ selectedPhoto });
    }

    render() {
        const { photos, className } = this.props;
        const { selectedPhoto } = this.state;

        const photoStyle = {
            backgroundImage: `url(${photos[selectedPhoto].photo})`,
        };

        let thumbnails = null;
        if (photos.length > 1) {
            const thumbButtons = photos.map((photoData, i) => (
                <button type="button" onClick={() => this.changePhoto(i)} key={photoData.thumbnail}>
                    <img src={photoData.thumbnail} alt="Thumbnail" />
                </button>
            ));
            thumbnails = (
                <div className={styles.thumbnails}>
                    {thumbButtons}
                </div>
            );
        }

        return (
            <div className={[styles.photoCarousel, className].join(' ')}>
                <div className={styles.photo} style={photoStyle} />
                {thumbnails}
            </div>
        );
    }
}

PhotoCarousel.propTypes = propTypes;
PhotoCarousel.defaultProps = defaultProps;

export default PhotoCarousel;
