import { SET_ITEMS } from '../actions';

function items(state = null, action) {
    let newState;

    switch (action.type) {
        case SET_ITEMS:
            newState = action.items || null;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default items;
