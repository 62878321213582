import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import App from './App';
import {
    setDimensions as setDimensionsAction,
    setIsMobile as setIsMobileAction,
} from '../../redux/actions';

const mapDispatchToProps = (dispatch) => {
    const setDimensions = (width, height) => dispatch(setDimensionsAction(width, height));
    const setIsMobile = isMobile => dispatch(setIsMobileAction(isMobile));

    return {
        setDimensions,
        setIsMobile,
    };
};

const AppContainer = withRouter(connect(
    null,
    mapDispatchToProps,
)(App));

export default AppContainer;
