const ADD_TO_CART = 'ADD_TO_CART';
function addToCart(itemData, variationData, quantity = 1) {
    const variation = {
        id: variationData.id,
        itemId: itemData.id,
        name: `${itemData.name} (${variationData.name})`,
        image: itemData.image,
        price: itemData.price,
    };

    return {
        type: ADD_TO_CART,
        variation,
        quantity,
    };
}

export {
    ADD_TO_CART,
    addToCart,
};
