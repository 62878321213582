import { connect } from 'react-redux';
import { scrollTo as scrollToAction } from '../../../redux/actions';
import Home from './Home';

const mapDispatchToProps = (dispatch) => {
    const scrollTo = target => dispatch(scrollToAction(target));

    return { scrollTo };
};

const HomeContainer = connect(
    null,
    mapDispatchToProps,
)(Home);

export default HomeContainer;
