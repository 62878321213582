import { connect } from 'react-redux';
import ItemsList from './ItemsList';
import { loadItems as loadItemsAction } from '../../../redux/actions';

const mapStateToProps = (state) => {
    const { items } = state;

    return { items };
};

const mapDispatchToProps = (dispatch) => {
    const loadItems = () => dispatch(loadItemsAction());

    return { loadItems };
};

const ItemsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemsList);

export default ItemsListContainer;
