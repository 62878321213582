import TweenLite, { Power1 } from 'gsap/umd/TweenLite';
import ScrollToPlugin from 'gsap/umd/ScrollToPlugin';

const gsapPlugins = [ScrollToPlugin];

function scrollTo(target, onComplete = null, immediate = false) {
    return () => {
        const appElement = document.getElementById('app');
        const scrollTime = immediate ? 0 : 0.5;

        return new Promise((resolve) => {
            TweenLite.to(appElement, scrollTime, {
                scrollTo: {
                    x: 0,
                    y: target,
                    autoKill: false,
                },
                onComplete: () => {
                    if (onComplete) {
                        onComplete();
                    }
                    resolve();
                },
                ease: Power1.easeOut,
            });
        });
    };
}

export { scrollTo };
