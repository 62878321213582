import { connect } from 'react-redux';
import ItemDetail from './ItemDetail';
import { addToCart as addToCartAction } from '../../../redux/actions';

const mapStateToProps = (state, { match }) => {
    const { items } = state;
    let itemData = null;

    if (items) {
        const itemDatas = Object.values(items);
        const itemId = parseInt(match.params.itemId, 10);

        for (let i = 0; i < itemDatas.length; i++) {
            if (itemDatas[i].id === itemId) {
                itemData = itemDatas[i];
                break;
            }
        }
    }

    return { items, itemData };
};

const mapDispatchToProps = (dispatch) => {
    const addToCart = (itemData, variationData, quantity) => (
        dispatch(addToCartAction(itemData, variationData, quantity))
    );

    return { addToCart };
};

const ItemDetailContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemDetail);

export default ItemDetailContainer;
