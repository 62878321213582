import React, { Component } from 'react';
import { arrayOf, objectOf, string } from 'prop-types';
import { randomPick } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    images: arrayOf(objectOf(string)).isRequired,
    className: string,
};

const defaultProps = {
    className: null,
};

const dpr = (typeof window !== 'undefined' ? window.devicePixelRatio : null) || 1;

class RandomImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedImage: null,
        };
    }

    componentDidMount() {
        this.changeImage();
    }

    componentDidUpdate(prevProps) {
        const { images } = this.props;

        if (images !== prevProps.images) {
            this.changeImage();
        }
    }

    changeImage() {
        const { images } = this.props;

        this.setState({ selectedImage: randomPick(images)[dpr] });
    }

    render() {
        const { selectedImage } = this.state;
        const { className } = this.props;

        if (!selectedImage) {
            return null;
        }

        const style = {
            backgroundImage: `url(${selectedImage}`,
        };

        return <div className={[styles.randomImage, className].join(' ')} style={style} />;
    }
}

RandomImage.propTypes = propTypes;
RandomImage.defaultProps = defaultProps;

export default RandomImage;
