import { connect } from 'react-redux';
import CartList from './CartList';

const mapStateToProps = (state) => {
    const { cart } = state;

    return { cart };
};

const CartListContainer = connect(
    mapStateToProps,
)(CartList);

export default CartListContainer;
