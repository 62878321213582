import React from 'react';
import { string } from 'prop-types';
import loadingSpinner from './loadingSpinner.svg';

const propTypes = {
    className: string,
};

const defaultProps = {
    className: null,
};

const LoadingSpinner = ({ className }) => (
    <img
        src={loadingSpinner}
        className={className}
        alt="Loading..."
    />
);

LoadingSpinner.propTypes = propTypes;
LoadingSpinner.defaultProps = defaultProps;

export default LoadingSpinner;
