import React from 'react';
import { bool } from 'prop-types';
import Carousel from '../../components/Carousel';
import RandomImage from '../../components/RandomImage';

import styles from './styles.scss';

const propTypes = {
    isMobile: bool.isRequired,
};

const collectionRouletteImages = [
    {
        1: '/static/images/collectionRoulette/collectionRoulette01.jpg',
        2: '/static/images/collectionRoulette/collectionRoulette01@2x.jpg',
        3: '/static/images/collectionRoulette/collectionRoulette01@3x.jpg',
    },
    {
        1: '/static/images/collectionRoulette/collectionRoulette02.jpg',
        2: '/static/images/collectionRoulette/collectionRoulette02@2x.jpg',
        3: '/static/images/collectionRoulette/collectionRoulette02@3x.jpg',
    },
    {
        1: '/static/images/collectionRoulette/collectionRoulette03.jpg',
        2: '/static/images/collectionRoulette/collectionRoulette03@2x.jpg',
        3: '/static/images/collectionRoulette/collectionRoulette03@3x.jpg',
    },
];

const carouselPhotosDesktop = [
    {
        1: '/static/images/collection/desktop/collection01.jpg',
        2: '/static/images/collection/desktop/collection01@2x.jpg',
        3: '/static/images/collection/desktop/collection01@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection02.jpg',
        2: '/static/images/collection/desktop/collection02@2x.jpg',
        3: '/static/images/collection/desktop/collection02@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection03.jpg',
        2: '/static/images/collection/desktop/collection03@2x.jpg',
        3: '/static/images/collection/desktop/collection03@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection04.jpg',
        2: '/static/images/collection/desktop/collection04@2x.jpg',
        3: '/static/images/collection/desktop/collection04@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection05.jpg',
        2: '/static/images/collection/desktop/collection05@2x.jpg',
        3: '/static/images/collection/desktop/collection05@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection06.jpg',
        2: '/static/images/collection/desktop/collection06@2x.jpg',
        3: '/static/images/collection/desktop/collection06@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection07.jpg',
        2: '/static/images/collection/desktop/collection07@2x.jpg',
        3: '/static/images/collection/desktop/collection07@3x.jpg',
    },
    {
        1: '/static/images/collection/desktop/collection08.jpg',
        2: '/static/images/collection/desktop/collection08@2x.jpg',
        3: '/static/images/collection/desktop/collection08@3x.jpg',
    },
];

const carouselPhotosMobile = [
    {
        1: '/static/images/collection/mobile/collection01.jpg',
        2: '/static/images/collection/mobile/collection01@2x.jpg',
        3: '/static/images/collection/mobile/collection01@3x.jpg',
    },
    {
        1: '/static/images/collection/mobile/collection02.jpg',
        2: '/static/images/collection/mobile/collection02@2x.jpg',
        3: '/static/images/collection/mobile/collection02@3x.jpg',
    },
    {
        1: '/static/images/collection/mobile/collection03.jpg',
        2: '/static/images/collection/mobile/collection03@2x.jpg',
        3: '/static/images/collection/mobile/collection03@3x.jpg',
    },
    {
        1: '/static/images/collection/mobile/collection04.jpg',
        2: '/static/images/collection/mobile/collection04@2x.jpg',
        3: '/static/images/collection/mobile/collection04@3x.jpg',
    },
    {
        1: '/static/images/collection/mobile/collection05.jpg',
        2: '/static/images/collection/mobile/collection05@2x.jpg',
        3: '/static/images/collection/mobile/collection05@3x.jpg',
    },
];

function Collection({ isMobile }) {
    return (
        <div className={styles.collection}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.title}>
                        Sobre a Coleção
                    </div>
                    <div className={styles.description}>
                        <p>
                            Temos a honra de compartilhar
                            a primeira coleção de produtos da DOJO Store,
                            uma loja online exclusiva
                            para a equipe e clientes da nossa agência.
                        </p>
                        <p>
                            Todos os itens que lançamos foram pensados para aprimorar diferentes
                            aspectos do dia a dia, levando nosso espírito criativo para os universos
                            da moda, decoração e arte.
                        </p>
                        <p>
                            Navegue pelo lookbook, descubra cada detalhe e fique atento porque,
                            logo menos, iremos trazer até você novas coleções cada vez
                            mais surpreendentes.
                        </p>
                    </div>
                </div>
                <RandomImage images={collectionRouletteImages} className={styles.roulette} />
            </div>
            <Carousel
                className={styles.carousel}
                photos={isMobile ? carouselPhotosMobile : carouselPhotosDesktop}
            />
        </div>
    );
}

Collection.propTypes = propTypes;

export default Collection;
