const CLEAR_CART = 'CLEAR_CART';
const START_PURCHASE = 'START_PURCHASE';
const END_PURCHASE = 'END_PURCHASE';
const OPEN_MENU = 'OPEN_MENU';
const CLOSE_MENU = 'CLOSE_MENU';

export {
    OPEN_MENU,
    CLOSE_MENU,
    CLEAR_CART,
    START_PURCHASE,
    END_PURCHASE,
};
