import { setItems } from '../setItems';
import { StoreService } from '../../../services';

function loadItems() {
    return dispatch => (
        StoreService.loadItems()
            .then((result) => {
                dispatch(setItems(result.message));
                return result.items;
            })
    );
}

export { loadItems };
