import React from 'react';
import Modal from '../../components/Modal';

import styles from './styles.scss';

function PurchaseResult() {
    return (
        <Modal>
            <div className={styles.purchaseResult}>
                Parabéns sua compra foi confirmada
            </div>
        </Modal>
    );
}

export default PurchaseResult;
