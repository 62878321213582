import { connect } from 'react-redux';
import { removeFromCart as removeFromCartAction } from '../../../redux/actions';
import CartItem from './CartItem';

const mapStateToProps = (state, { itemData }) => ({ itemData });

const mapDispatchToProps = (dispatch, { removable }) => {
    if (!removable) {
        return {};
    }

    const removeFromCart = itemId => dispatch(removeFromCartAction(itemId));

    return { removeFromCart };
};

const CartItemContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartItem);

export default CartItemContainer;
