import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { func } from 'prop-types';
import Header from '../../components/Header';
import Instructions from '../../components/Instructions';
import ItemsList from '../../components/ItemsList';
import Footer from '../../components/Footer';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    scrollTo: func.isRequired,
};

class Home extends Component {
    componentDidMount() {
        const { scrollTo } = this.props;

        scrollTo(0);
    }

    render() {
        return (
            <div className={styles.home}>
                <Header />
                {/* <Instructions /> */}
                <ItemsList />
                <Link to={routes.cart()} className={styles.proceedToCart}>
                    Carrinho
                </Link>
                <Footer />
            </div>
        );
    }
}

Home.propTypes = propTypes;

export default Home;
