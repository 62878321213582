const SET_IS_MOBILE = 'SET_IS_MOBILE';
function setIsMobile(isMobile) {
    return {
        type: SET_IS_MOBILE,
        isMobile,
    };
}

export {
    SET_IS_MOBILE,
    setIsMobile,
};
