import React, { Component } from 'react';
import {
    oneOfType, arrayOf, node, string, bool, object, number, func,
} from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    children: oneOfType([node, arrayOf(node)]).isRequired,
    scatter: number,
    delay: number,
    active: bool,
    className: string,
    tokenClass: string,
    style: object,
    tokenStyle: object,
    onClick: func,
};

const defaultProps = {
    scatter: 0,
    delay: 0,
    active: true,
    className: null,
    tokenClass: null,
    style: null,
    tokenStyle: null,
    onClick: null,
};

class Sentence extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
        };
    }

    componentDidMount() {
        this.setState({ initialized: true });
    }

    render() {
        const {
            children,
            delay,
            scatter,
            active,
            className,
            tokenClass,
            style,
            tokenStyle,
            onClick,
        } = this.props;
        const { initialized } = this.state;

        const tokens = children instanceof Array ? children : [children];
        const tokenNodes = tokens.map((token, i) => {
            const tokenStyleDict = {
                ...tokenStyle,
                transitionDelay: `${i * scatter + delay}s`,
            };

            const tokenClasses = [styles.word, tokenClass];
            if (active && initialized) {
                tokenClasses.push(styles.active);
            }

            return (
                <span style={tokenStyleDict} className={tokenClasses.join(' ')} key={i}>
                    {token}
                </span>
            );
        });

        const SentenceType = onClick ? 'button' : 'div';

        return (
            <SentenceType
                className={[styles.sentence, className].join(' ')}
                style={style}
                onClick={onClick}
            >
                {tokenNodes}
            </SentenceType>
        );
    }
}

Sentence.propTypes = propTypes;
Sentence.defaultProps = defaultProps;

export default Sentence;
