import React from 'react';
import { string, node } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    title: string.isRequired,
    children: node.isRequired,
};

const CheckoutPage = ({ title, children }) => (
    <div className={styles.checkoutPage}>
        <div className={styles.title}>
            {title}
        </div>
        <div className={styles.content}>
            {children}
        </div>
    </div>
);

CheckoutPage.propTypes = propTypes;

export default CheckoutPage;
