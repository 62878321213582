import { connect } from 'react-redux';
import Menu from './Menu';
import {
    OPEN_MENU,
    CLOSE_MENU,
    scrollTo as scrollToAction,
} from '../../../redux/actions';

const mapStateToProps = ({ isMenuOpen }) => ({ isMenuOpen });

const mapDispatchToProps = (dispatch) => {
    const scrollToProducts = () => dispatch(scrollToAction('#products'));
    const openMenu = () => dispatch(OPEN_MENU);
    const closeMenu = () => dispatch(CLOSE_MENU);

    return { scrollToProducts, openMenu, closeMenu };
};

const MenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Menu);

export default MenuContainer;
