function storedReducer(reducer, key = null) {
    return (state, action) => {
        const newState = reducer(state, action);
        if (state !== newState
            && typeof window !== 'undefined'
            && action.type.indexOf('@@redux') < 0) {
            const __STORED_STATE__ = JSON.parse(
                window.localStorage.getItem(storedReducer.storageKey),
            ) || {};

            __STORED_STATE__[key || reducer.name] = newState;

            window.localStorage.setItem(
                storedReducer.storageKey,
                JSON.stringify(__STORED_STATE__),
            );
        }

        return newState;
    };
}

storedReducer.storageKey = '__STORED_STATE__';

export default storedReducer;
