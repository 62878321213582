import React from 'react';
import { string, node } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    title: string.isRequired,
    children: node.isRequired,
    contentClass: string,
};

const defaultProps = {
    contentClass: null,
};

const ContentBox = ({ title, children, contentClass }) => (
    <div className={styles.contentBox}>
        <div className={styles.title}>
            {title}
        </div>
        <div className={[styles.content, contentClass].join(' ')}>
            {children}
        </div>
    </div>
);

ContentBox.propTypes = propTypes;
ContentBox.defaultProps = defaultProps;

export default ContentBox;
