/* eslint camelcase:off */
import fetch from 'node-fetch';
import { config } from '../src/utils';

const BASE_PATH = config.apiPath[process.env.NODE_ENV] || config.apiPath.development;

const BaseAPI = {
    doGETRequest(endpoint) {
        return fetch(`${BASE_PATH}${endpoint}`)
            .then(res => res.json())
            .catch((error) => {
                console.log('Fetch error', error);
                return { error };
            });
    },

    doPOSTRequest(endpoint, body = null) {
        return fetch(`${BASE_PATH}${endpoint}`, {
            method: 'POST',
            body,
            headers: {
                Accept: 'application/json',
                // 'Content-Type': 'multipart/form-data',
            },
        })
            .then(res => res.json())
            .catch((error) => {
                console.log('Fetch error', error);
                return { error };
            });
    },
};

export default BaseAPI;
