import React from 'react';
import { object, func } from 'prop-types';
import { formatPrice, config } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    itemData: object.isRequired,
    removeFromCart: func,
};

const defaultProps = {
    removeFromCart: null,
};

const CartItem = ({ itemData, removeFromCart }) => {
    const removeButtonClasses = [styles.removeButton];
    if (!removeFromCart) {
        removeButtonClasses.push(styles.disabled);
    }

    const removeButton = (
        <button
            className={removeButtonClasses.join(' ')}
            onClick={removeFromCart ? () => removeFromCart(itemData.id) : null}
            type="button"
        >
            Remover
        </button>
    );

    return (
        <div className={styles.cartItem}>
            <img src={itemData.image} alt={itemData.name} />
            <div className={styles.description}>
                <div className={styles.itemName}>
                    {itemData.name}
                </div>
                <div>
                    <div className={styles.info}>
                        Quantidade:
                        {' '}
                        {itemData.count}
                    </div>
                    {!config.showPrices ? null : [
                        <div className={styles.info} key="unitPrice">
                            Preço unitário:
                            {' '}
                            {formatPrice(itemData.price)}
                        </div>,
                        <div className={styles.info} key="totalPrice">
                            Total:
                            {' '}
                            {formatPrice(itemData.price * itemData.count)}
                        </div>,
                    ]}
                </div>
                {removeButton}
            </div>
        </div>
    );
};

CartItem.propTypes = propTypes;
CartItem.defaultProps = defaultProps;

export default CartItem;
