import BaseAPI from './BaseApi';

const StoreService = {
    ...BaseAPI,

    loadItems() {
        return this.doGETRequest('/items/');
    },

    doPurchase(payload) {
        return this.doPOSTRequest('/purchase/', payload);
    },
};

export default StoreService;
