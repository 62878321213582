import React, { Component } from 'react';
import {
    number, func, string, object,
} from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    slots: number.isRequired,
    selectedStory: number.isRequired,
    storyDuration: number.isRequired,
    startNextStory: func,
    className: string,
    style: object,
};

const defaultProps = {
    startNextStory: null,
    className: null,
    style: null,
};

class StoryTimer extends Component {
    constructor(props) {
        super(props);

        this.timerStart = null;
        this.animationId = null;
        this.storyEnded = false;

        this.state = {
            progress: 0,
        };

        this.animationStep = this.animationStep.bind(this);
    }

    componentDidMount() {
        this.resetTimer();
        this.animationId = window.requestAnimationFrame(this.animationStep);
    }

    componentDidUpdate(prevProps) {
        const { selectedStory } = this.props;

        if (prevProps.selectedStory !== selectedStory) {
            this.resetTimer();
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.animationId);
    }

    resetTimer() {
        this.timerStart = new Date().getTime();
        this.storyEnded = false;
        this.setState({ progress: 0 });
    }

    animationStep() {
        this.animationId = window.requestAnimationFrame(this.animationStep);

        const { storyDuration, startNextStory } = this.props;
        const now = new Date().getTime();
        const progress = 100 * (now - this.timerStart) / storyDuration;

        if (progress >= 100 && !this.storyEnded) {
            startNextStory();
        }

        this.setState({ progress });
    }

    render() {
        const {
            slots, selectedStory, className, style,
        } = this.props;
        const { progress } = this.state;

        const storyBars = new Array(slots).fill(0).map((x, i) => {
            let barProgress;
            if (i < selectedStory) {
                barProgress = 100;
            } else if (i > selectedStory) {
                barProgress = 0;
            } else {
                barProgress = Math.min(100, progress);
            }

            return (
                <div className={styles.storyBar} key={i}>
                    <div className={styles.storyContainer}>
                        <div
                            className={styles.storyProgress}
                            style={{ width: `${barProgress}%` }}
                        />
                    </div>
                </div>
            );
        });

        return (
            <div
                className={[styles.storyTimer, className].join(' ')}
                style={style}
            >
                {storyBars}
            </div>
        );
    }
}

StoryTimer.propTypes = propTypes;
StoryTimer.defaultProps = defaultProps;

export default StoryTimer;
