import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    isMenuOpen: bool.isRequired,
    closeMenu: func.isRequired,
    scrollTo: func.isRequired,
};

class MenuView extends Component {
    constructor(props) {
        super(props);

        this.closeAndScroll = this.closeAndScroll.bind(this);
    }

    closeAndScroll(target) {
        const { scrollTo, closeMenu } = this.props;

        closeMenu();
        scrollTo(target);
    }

    render() {
        const { isMenuOpen, closeMenu } = this.props;

        const classes = [styles.menuView];
        if (isMenuOpen) {
            classes.push(styles.open);
        }

        return (
            <div className={classes.join(' ')}>
                <Link to={routes.home()} className={styles.menuItem} onClick={() => this.closeAndScroll('#header')}>
                    Home
                </Link>
                <Link to={routes.home()} className={styles.menuItem} onClick={() => this.closeAndScroll('#products')}>
                    Produtos
                </Link>
                <Link to={routes.collection()} className={styles.menuItem} onClick={closeMenu}>
                    Coleção
                </Link>
                <Link to={routes.cart()} className={styles.menuItem} onClick={closeMenu}>
                    Carrinho
                </Link>
            </div>
        );
    }
}

MenuView.propTypes = propTypes;

export default MenuView;
