function formatPrice(price) {
    let result = `${price}`;
    if (result.length < 3) {
        result = `00${result}`;
        result = result.substr(result.length - 3);
    }

    return `R$ ${result.substr(0, result.length - 2)}.${result.substr(result.length - 2)}`;
}

export default formatPrice;
