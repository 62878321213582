const SET_ITEMS = 'SET_ITEMS';
function setItems(items) {
    return {
        type: SET_ITEMS,
        items,
    };
}

export {
    SET_ITEMS,
    setItems,
};
