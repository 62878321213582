import { OPEN_MENU, CLOSE_MENU } from '../actions';

function isMenuOpen(state = false, action) {
    let newState;

    switch (action.type) {
        case OPEN_MENU:
            newState = true;
            break;

        case CLOSE_MENU:
            newState = false;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default isMenuOpen;
