const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
function removeFromCart(variationId) {
    return {
        type: REMOVE_FROM_CART,
        variationId,
    };
}

export {
    REMOVE_FROM_CART,
    removeFromCart,
};
