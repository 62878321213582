import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { arrayOf, object, func } from 'prop-types';
import Modal from '../../components/Modal';
import QuantitySelector from '../../components/QuantitySelector';
import PhotoCarousel from '../../components/PhotoCarousel';
import { routes, config, formatPrice } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    items: arrayOf(object),
    itemData: object,
    addToCart: func.isRequired,
};

const defaultProps = {
    items: null,
    itemData: null,
};

class ItemDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVariation: 0,
            quantity: 1,
            descriptionVisible: false,
        };

        this.onQuantityChange = this.onQuantityChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onVariationSelect = this.onVariationSelect.bind(this);
        this.switchDescriptionVisible = this.switchDescriptionVisible.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { itemData } = this.props;

        if (prevProps.itemData !== itemData) {
            this.setState({ selectedVariation: 0 });
        }
    }

    onQuantityChange(quantity) {
        const newQuantity = Math.max(1, quantity);

        this.setState({ quantity: newQuantity });
    }

    onVariationSelect(e) {
        this.setState({ selectedVariation: parseInt(e.target.value, 10) });
    }

    onAddClick() {
        const { addToCart, itemData } = this.props;
        const { quantity, selectedVariation } = this.state;
        const variationData = itemData.variations[selectedVariation];

        if (quantity <= 0) {
            return;
        }

        addToCart(itemData, variationData, quantity);
        this.setState({ quantity: 0 });
    }

    switchDescriptionVisible() {
        const { descriptionVisible } = this.state;

        this.setState({ descriptionVisible: !descriptionVisible });
    }

    render() {
        const { items, itemData } = this.props;
        const { quantity, selectedVariation, descriptionVisible } = this.state;

        if (!items) {
            return <div className={styles.itemDetail} />;
        }

        if (!itemData) {
            return <Redirect to={routes.home()} replace />;
        }

        const descriptionContent = [];
        itemData.description.split(/\r?\n/).forEach((token, i) => {
            if (i > 0) {
                descriptionContent.push(<br key={i} />);
            }
            descriptionContent.push(token);
        });

        let variationSelector = null;
        if (itemData.variations.length > 1) {
            const variationOptions = itemData.variations.map(
                (v, i) => <option key={v.id} value={i}>{v.name}</option>,
            );
            variationSelector = [
                <div className={styles.parameterTitle} key="variationTitle">
                    Tipo
                </div>,
                <select
                    key="variationSelector"
                    value={selectedVariation}
                    onChange={this.onVariationSelect}
                    className={styles.variationSelector}
                >
                    {variationOptions}
                </select>,
            ];
        }

        const quantityOptions = [];
        for (let i = 1; i < (itemData.limit_one ? 2 : 6); i++) {
            quantityOptions.push(<option value={i} key={i}>{i}</option>);
        }

        let mobileDescription;
        if (descriptionVisible) {
            mobileDescription = (
                <div className={styles.descriptionMobile}>
                    {descriptionContent}
                </div>
            );
        } else {
            mobileDescription = null;
        }

        let quantitySelector;
        if (itemData.variations[selectedVariation].available || config.allowOnDemandPurchase) {
            quantitySelector = [
                <QuantitySelector
                    key="selector"
                    value={quantity}
                    onChange={this.onQuantityChange}
                    className={styles.quantityFieldDesktop}
                />,
                <select
                    key="selectorMobile"
                    className={styles.quantityFieldMobile}
                    value={quantity}
                    onChange={e => this.onQuantityChange(e.target.value)}
                >
                    {quantityOptions}
                </select>,
                <Link
                    key="addButton"
                    to={routes.cart()}
                    className={styles.addButton}
                    onClick={this.onAddClick}
                >
                    Adicionar
                </Link>,
            ];
        } else {
            quantitySelector = (
                <div className={styles.soldOut}>
                    Esgotado
                </div>
            );
        }

        return (
            <Modal>
                <div className={styles.content}>
                    <div className={styles.headerMobile}>
                        <div className={styles.title}>
                            {itemData.name}
                        </div>
                        <button
                            className={styles.moreInfo}
                            type="button"
                            onClick={this.switchDescriptionVisible}
                        >
                            Mais Info +
                        </button>
                    </div>
                    {mobileDescription}
                    <PhotoCarousel
                        className={styles.photoCarousel}
                        photos={itemData.photos}
                    />
                    <div className={styles.data}>
                        <div className={styles.titleDesktop}>
                            {itemData.name}
                        </div>
                        <div className={styles.descriptionDesktop}>
                            {descriptionContent}
                        </div>
                        {!config.showPrices ? null : (
                            <div className={styles.itemPrice}>
                                Preço:
                                {' '}
                                {formatPrice(quantity * itemData.price)}
                            </div>
                        )}
                        {variationSelector}
                        <div className={styles.parameterTitle}>
                            Quantidade
                        </div>
                        {quantitySelector}
                        <Link to={routes.cart()} className={styles.proceedLink}>
                            Finalizar o pack.
                        </Link>
                        <Link to={routes.home()} className={styles.backToStore}>
                            &lt; Voltar para loja.
                        </Link>
                    </div>
                </div>
            </Modal>
        );
    }
}

ItemDetail.propTypes = propTypes;
ItemDetail.defaultProps = defaultProps;

export default ItemDetail;
