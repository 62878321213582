import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { number, bool } from 'prop-types';
import RandomImage from '../RandomImage';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    width: number.isRequired,
    isMobile: bool.isRequired,
};

const headerRouletteImagesDesktop = [
    {
        1: '/static/images/homeRoulette/homeRoulette01.jpg',
        2: '/static/images/homeRoulette/homeRoulette01@2x.jpg',
        3: '/static/images/homeRoulette/homeRoulette01@3x.jpg',
    },
    {
        1: '/static/images/homeRoulette/homeRoulette02.jpg',
        2: '/static/images/homeRoulette/homeRoulette02@2x.jpg',
        3: '/static/images/homeRoulette/homeRoulette02@3x.jpg',
    },
    {
        1: '/static/images/homeRoulette/homeRoulette03.jpg',
        2: '/static/images/homeRoulette/homeRoulette03@2x.jpg',
        3: '/static/images/homeRoulette/homeRoulette03@3x.jpg',
    },
];

const headerRouletteImagesMobile = [
    {
        1: '/static/images/homeRoulette/homeRoulette01.jpg',
        2: '/static/images/homeRoulette/homeRoulette01@2x.jpg',
        3: '/static/images/homeRoulette/homeRoulette01@3x.jpg',
    },
    {
        1: '/static/images/homeRoulette/homeRoulette03.jpg',
        2: '/static/images/homeRoulette/homeRoulette03@2x.jpg',
        3: '/static/images/homeRoulette/homeRoulette03@3x.jpg',
    },
];

class Header extends Component {
    constructor(props) {
        super(props);

        this.dragStartX = null;

        this.state = {
            titlePosition: 50,
        };

        this.adjustTitlePosition = this.adjustTitlePosition.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    onTouchStart(e) {
        this.dragStartX = e.touches[0].pageX;
    }

    onTouchMove(e) {
        if (this.dragStartX === null) {
            return;
        }

        let { titlePosition } = this.state;
        const touchPos = e.touches[0].pageX;
        titlePosition -= touchPos - this.dragStartX;
        this.dragStartX = touchPos;
        this.setState({ titlePosition });
    }

    onTouchEnd() {
        this.dragStartX = null;
    }

    adjustTitlePosition(e) {
        const { width } = this.props;

        this.setState({ titlePosition: 300 - 600 * e.pageX / width });
    }

    render() {
        const { titlePosition } = this.state;
        const { isMobile } = this.props;

        const titleStyle = {
            backgroundPosition: `${titlePosition}% 50%`,
        };

        return (
            <div
                id="header"
                className={styles.header}
                onMouseMove={this.adjustTitlePosition}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
            >
                <RandomImage
                    images={isMobile ? headerRouletteImagesMobile : headerRouletteImagesDesktop}
                    className={styles.image}
                />
                <div className={styles.dojoTitle} style={titleStyle} />
                <Link to={routes.collection()} className={styles.collectionLink}>
                    Sobre a Coleção
                </Link>
            </div>
        );
    }
}

Header.propTypes = propTypes;

export default Header;
