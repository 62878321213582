const routes = {
    all: /^(\/[^.]*)+\/?$/,
    home: () => '/',
    itemDetail: variationId => `/item-detail/${variationId}/`,
    cart: () => '/cart',
    checkout: () => '/checkout',
    purchaseResult: () => '/purchase-result',
    collection: () => '/collection',
};

export default routes;
