import React, { Component } from 'react';
import { arrayOf, object, func } from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';
import Item from '../Item';

import styles from './styles.scss';

const propTypes = {
    items: arrayOf(object),
    loadItems: func.isRequired,
};

const defaultProps = {
    items: null,
};

class ItemsList extends Component {
    componentDidMount() {
        const { items, loadItems } = this.props;

        if (!items) {
            loadItems();
        }
    }

    render() {
        const { items } = this.props;

        if (!items) {
            return (
                <LoadingSpinner className={styles.spinner} id="products" />
            );
        }

        const itemComponents = items.map(
            itemData => <Item itemData={itemData} key={itemData.id} />,
        );

        return (
            <div className={styles.itemsList} id="products">
                {itemComponents}
            </div>
        );
    }
}

ItemsList.propTypes = propTypes;
ItemsList.defaultProps = defaultProps;

export default ItemsList;
