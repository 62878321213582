import { connect } from 'react-redux';
import {
    CLOSE_MENU,
    scrollTo as scrollToAction,
} from '../../../redux/actions';
import MenuView from './MenuView';

const mapStateToProps = (state) => {
    const { isMenuOpen } = state;

    return { isMenuOpen };
};

const mapDispatchToProps = (dispatch) => {
    const closeMenu = () => dispatch(CLOSE_MENU);
    const scrollTo = (target, onComplete) => dispatch(scrollToAction(target, onComplete));

    return { closeMenu, scrollTo };
};

const MenuViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MenuView);

export default MenuViewContainer;
