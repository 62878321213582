import React from 'react';

import styles from './styles.scss';

const Footer = () => (
    <div className={styles.footer}>
        <div className={styles.infoGrid}>
            <div className={[styles.infoBlock, styles.sp].join(' ')}>
                <div className={styles.title}>
                    São Paulo Office
                </div>
                <a
                    href="https://goo.gl/maps/GcGmx8nVYGH591oV9"
                    className={styles.content}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Avenida República do Líbano, 344
                    <br />
                    São Paulo, Brasil
                    <br />
                    04502-000
                </a>
                <br />
                <br />
                <a
                    href="tel:+55-11-3135-7400"
                    className={styles.content}
                >
                    +55
                    {' '}
                    (11)
                    {' '}
                    3135-7400
                </a>
            </div>
            <div className={[styles.infoBlock, styles.business].join(' ')}>
                <div className={styles.title}>
                    Business Inquires
                </div>
                <a href="mailto:business@dojo.do" className={styles.content}>
                    business@dojo.do
                </a>
            </div>
            <div className={[styles.infoBlock, styles.media].join(' ')}>
                <div className={styles.title}>
                    Media Inquires
                </div>
                <a href="mailto:media@dojo.do" className={styles.content}>
                    media@dojo.do
                </a>
            </div>
            <div className={[styles.infoBlock, styles.press].join(' ')}>
                <div className={styles.title}>
                    Press Contact
                </div>
                <a href="mailto:gricci@dojo.do" className={styles.content}>
                    gricci@dojo.do
                </a>
            </div>
            <div className={styles.copyright}>
                Dojo ©
                {' '}
                {new Date().getFullYear()}
            </div>
            <div className={styles.social}>
                <a href="https://www.instagram.com/dojo.do/" target="_blank" rel="noopener noreferrer">
                    Siga-nos no Instagram
                </a>
            </div>
        </div>
    </div>
);

export default Footer;
