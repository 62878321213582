import React from 'react';
import { string, node } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    className: string,
    children: node.isRequired,
};

const defaultProps = {
    className: null,
};

function Modal({ className, children }) {
    return (
        <div className={[className, styles.modal].join(' ')}>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
