import React from 'react';
import { Link } from 'react-router-dom';
import {
    bool,
    string,
    object,
    number,
    node,
} from 'prop-types';
import { routes } from '../../utils';
import Sentence from '../Sentence';
import letterD from './d.svg';
import letterO from './o.svg';
import letterJ from './j.svg';

import styles from './styles.scss';

const propTypes = {
    className: string,
    style: object,
    active: bool,
    delay: number,
    children: node,
};

const defaultProps = {
    className: null,
    style: null,
    active: false,
    delay: 0,
    children: null,
};

function Dojo({
    className, style, active, delay, children,
}) {
    const classes = [styles.dojo, className];

    return (
        <Link to={routes.home()} className={classes.join(' ')} style={style}>
            <Sentence active={active} scatter={0.05} delay={delay}>
                <img src={letterD} className={styles.letter} alt="D" />
                <img src={letterO} className={styles.letter} alt="O" />
                <img src={letterJ} className={styles.letter} alt="J" />
                <img src={letterO} className={styles.letter} alt="O" />
            </Sentence>
            <Sentence active={active} delay={delay + 3 * 0.05}>
                <div className={styles.children}>
                    {children}
                </div>
            </Sentence>
        </Link>
    );
}

Dojo.propTypes = propTypes;
Dojo.defaultProps = defaultProps;

export default Dojo;
