import React from 'react';
import { bool, func, string } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    close: bool,
    onClick: func,
    className: string,
};

const defaultProps = {
    close: false,
    onClick: null,
    className: null,
};

function HamburgerButton({ close, onClick, className }) {
    const classes = [styles.hamburgerButton, className];
    if (close) {
        classes.push(styles.close);
    }

    return (
        <button
            className={classes.join(' ')}
            onClick={onClick}
            type="button"
            tabIndex={0}
        >
            <div className={styles.line} />
            <div className={styles.line} />
        </button>
    );
}

HamburgerButton.propTypes = propTypes;
HamburgerButton.defaultProps = defaultProps;

export default HamburgerButton;
