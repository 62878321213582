import React, { Component } from 'react';
import { func } from 'prop-types';

import styles from './styles.scss';

const propTypes = {
    scrollTo: func.isRequired,
};

class Instructions extends Component {
    constructor(props) {
        super(props);

        this.scrollToProducts = this.scrollToProducts.bind(this);
    }

    scrollToProducts() {
        const { scrollTo } = this.props;

        scrollTo('#products');
    }

    render() {
        return (
            <div className={styles.instructions} id="instructions">
                <div className={styles.instructionBox}>
                    <div className={styles.index}>
                        01
                    </div>
                    <div className={styles.title}>
                        Escolha os produtos
                    </div>
                    <div className={styles.description}>
                        Navegue e adicione ao carrinho o que você gostar.
                        Como a gente sabe que vai ser difícil escolher só um produto,
                        você pode montar seu pack com até cinco.
                    </div>
                </div>
                <div className={styles.instructionBox}>
                    <div className={styles.index}>
                        02
                    </div>
                    <div className={styles.title}>
                        Finalize o pedido
                    </div>
                    <div className={styles.description}>
                        Assim que seu pack estiver pronto, vá até o carrinho e complete o pedido
                        com o login e a senha que a DOJO enviou para você por e-mail.
                    </div>
                </div>
                <div className={styles.instructionBox}>
                    <div className={styles.index}>
                        03
                    </div>
                    <div className={styles.title}>
                        E pronto
                    </div>
                    <div className={styles.description}>
                        Em pouco tempo, seu pack será entregue em mãos para você se tornar
                        parte do nosso clã de Black-Belts.
                    </div>
                </div>
            </div>
        );
    }
}

Instructions.propTypes = propTypes;

export default Instructions;
