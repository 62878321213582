import { connect } from 'react-redux';
import Collection from './Collection';

const mapStateToProps = ({ isMobile }) => ({ isMobile });

const CollectionContainer = connect(
    mapStateToProps,
)(Collection);

export default CollectionContainer;
