import React from 'react';
import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import CartCount from '../CartCount';
import HamburgerButton from '../HamburgerButton';
import { routes } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    isMenuOpen: bool.isRequired,
    scrollToProducts: func.isRequired,
    openMenu: func.isRequired,
    closeMenu: func.isRequired,
};

function Menu({
    isMenuOpen,
    scrollToProducts,
    openMenu,
    closeMenu,
}) {
    return (
        <div className={styles.menu}>
            <Link to={routes.home()} onClick={scrollToProducts} className={styles.link}>
                Produtos
            </Link>
            <Link to={routes.collection()} className={styles.link}>
                Coleção
            </Link>
            <Link to={routes.cart()} className={styles.link}>
                Carrinho
            </Link>
            <CartCount />
            <HamburgerButton
                className={styles.hamburgerButton}
                close={isMenuOpen}
                onClick={isMenuOpen ? closeMenu : openMenu}
            />
        </div>
    );
}

Menu.propTypes = propTypes;

export default Menu;
