import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { func } from 'prop-types';
import Home from '../views/Home';
import ItemDetail from '../views/ItemDetail';
import CartList from '../views/CartList';
import Checkout from '../views/Checkout';
import PurchaseResult from '../views/PurchaseResult';
import Collection from '../views/Collection';
import Dojo from '../components/Dojo';
import Menu from '../components/Menu';
import Ideogram from '../components/Ideogram';
import MenuView from '../components/MenuView';
import { routes } from '../utils';

import styles from './styles.scss';

const DESKTOP_MEDIA = styles.desktop.replace(/"/g, '');

const propTypes = {
    setDimensions: func.isRequired,
    setIsMobile: func.isRequired,
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dojoActive: false,
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();

        window.requestAnimationFrame(() => this.setState({ dojoActive: true }));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const { setDimensions, setIsMobile } = this.props;

        setDimensions(window.innerWidth, window.innerHeight);
        setIsMobile(!window.matchMedia(DESKTOP_MEDIA).matches);
    }

    render() {
        const { dojoActive } = this.state;

        return (
            <div className={styles.app} id="app">
                <Helmet>
                    <title>DOJO Store</title>
                </Helmet>
                <Route path={routes.home()} component={Home} />
                <Route path={routes.itemDetail(':itemId')} component={ItemDetail} />
                <Route path={routes.cart()} component={CartList} />
                <Route path={routes.checkout()} component={Checkout} />
                <Route path={routes.purchaseResult()} component={PurchaseResult} />
                <Route path={routes.collection()} component={Collection} />
                <Dojo active={dojoActive} delay={1} className={styles.slogan}>
                    store
                </Dojo>
                <Menu />
                <Ideogram />
                <MenuView />
            </div>
        );
    }
}

App.propTypes = propTypes;

export default App;
