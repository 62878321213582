import React, { Component } from 'react';
import {
    arrayOf,
    objectOf,
    string,
    object,
    bool,
} from 'prop-types';
import StoryTimer from '../StoryTimer';
import { config } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    photos: arrayOf(objectOf(string)),
    isMobile: bool.isRequired,
    className: string,
    style: object,
};

const defaultProps = {
    photos: [],
    className: null,
    style: null,
};

const dpr = (typeof window !== 'undefined' ? window.devicePixelRatio : null) || 1;

class Carousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPhoto: 0,
        };

        this.selectNextPhoto = this.selectNextPhoto.bind(this);
        this.selectPreviousPhoto = this.selectPreviousPhoto.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    onTouchStart(e) {
        this.dragStartX = e.touches[0].pageX;
    }

    onTouchMove(e) {
        if (this.dragStartX === null) {
            return;
        }

        const delta = e.touches[0].pageX - this.dragStartX;
        if (delta > config.dragThreshold) {
            this.onTouchEnd();
            this.selectPreviousPhoto();
        }
        if (delta < -config.dragThreshold) {
            this.onTouchEnd();
            this.selectNextPhoto();
        }
    }

    onTouchEnd() {
        this.dragStartX = null;
    }

    selectNextPhoto() {
        const { photos } = this.props;
        const { currentPhoto } = this.state;

        this.setState({ currentPhoto: (currentPhoto + 1) % photos.length });
    }

    selectPreviousPhoto() {
        const { photos } = this.props;
        const { currentPhoto } = this.state;

        this.setState({ currentPhoto: (currentPhoto + photos.length - 1) % photos.length });
    }

    render() {
        const {
            photos,
            className,
            style,
            isMobile,
        } = this.props;
        const { currentPhoto } = this.state;

        const photoNodes = photos.map((photo, i) => (
            <div
                className={styles.carouselPhoto}
                style={{ backgroundImage: `url(${photo[dpr]})` }}
                key={i}
            />
        ));

        const photosStyle = {
            transform: `translateX(-${currentPhoto * 100}%)`,
        };

        let storyTimer = null;
        if (isMobile) {
            storyTimer = (
                <StoryTimer
                    className={styles.storyTimer}
                    slots={photos.length}
                    selectedStory={currentPhoto}
                    storyDuration={config.storyDuration}
                    startNextStory={this.selectNextPhoto}
                />
            );
        }

        return (
            <div
                className={[styles.carousel, className].join(' ')}
                style={style}
                onTouchStart={this.onTouchStart}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
            >
                {storyTimer}
                <div className={styles.carouselPhotos} style={photosStyle}>
                    {photoNodes}
                </div>
                <button
                    type="button"
                    className={styles.leftArrow}
                    onClick={this.selectPreviousPhoto}
                />
                <button
                    type="button"
                    className={styles.rightArrow}
                    onClick={this.selectNextPhoto}
                />
            </div>
        );
    }
}

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
