import {
    ADD_TO_CART,
    CLEAR_CART,
    REMOVE_FROM_CART,
} from '../actions';
import { deepClone } from '../../src/utils';

import { storedReducer } from '../utils';

function cart(state = {}, action) {
    let newState;

    switch (action.type) {
        case ADD_TO_CART:
            newState = deepClone(state);

            if (!newState[action.variation.id]) {
                newState[action.variation.id] = {
                    ...action.variation,
                    count: 0,
                };
            }
            newState[action.variation.id].count += action.quantity;
            break;

        case REMOVE_FROM_CART:
            newState = deepClone(state);
            delete newState[action.variationId];
            break;

        case CLEAR_CART:
            newState = {};
            break;

        default:
            newState = state;
    }

    return newState;
}

export default storedReducer(cart, 'cart');
