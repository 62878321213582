import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { routes, formatPrice, config } from '../../utils';

import styles from './styles.scss';

const propTypes = {
    itemData: object.isRequired,
};

function Item({ itemData }) {
    return (
        <div className={styles.item}>
            <div className={styles.header}>
                <div className={styles.type}>
                    {itemData.type}
                </div>
                <div className={styles.name}>
                    {itemData.name}
                </div>
            </div>
            <Link to={routes.itemDetail(itemData.id)} className={styles.itemLink}>
                <img src={itemData.image} alt={itemData.name} />
                {!config.showPrices ? null : (
                    <div className={styles.price}>
                        {formatPrice(itemData.price)}
                    </div>
                )}
            </Link>
        </div>
    );
}

Item.propTypes = propTypes;

export default Item;
