import React from 'react';
import { number, func, string } from 'prop-types';
import styles from './styles.scss';

const propTypes = {
    value: number.isRequired,
    onChange: func.isRequired,
    className: string,
};

const defaultProps = {
    className: null,
};

function QuantitySelector({ value, onChange, className }) {
    return (
        <div className={[styles.quantitySelector, className].join(' ')}>
            {value}
            <div className={styles.buttons}>
                <button type="button" onClick={() => onChange(value + 1)}>+</button>
                <button type="button" onClick={() => onChange(value - 1)}>-</button>
            </div>
        </div>
    );
}

QuantitySelector.propTypes = propTypes;
QuantitySelector.defaultProps = defaultProps;

export default QuantitySelector;
