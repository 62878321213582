import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../utils';

import styles from './styles.scss';

const Ideogram = () => (
    <Link to={routes.home()}>
        <div className={styles.ideogram} />
    </Link>
);

export default Ideogram;
