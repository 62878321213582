import { connect } from 'react-redux';
import Carousel from './Carousel';

const mapStateToProps = ({ isMobile }, props) => ({ isMobile, ...props });

const CarouselContainer = connect(
    mapStateToProps,
)(Carousel);

export default CarouselContainer;
