import { SET_IS_MOBILE } from '../actions';

function isMobile(state = true, action) {
    let newState;

    switch (action.type) {
        case SET_IS_MOBILE:
            newState = action.isMobile;
            break;

        default:
            newState = state;
    }

    return newState;
}

export default isMobile;
