import { connect } from 'react-redux';
import CartCount from './CartCount';

const mapStateToProps = (state, { className }) => {
    const { cart } = state;
    const count = Object.values(cart).map(v => v.count).reduce((a, b) => a + b, 0);

    return { count, className };
};

const CartCountContainer = connect(
    mapStateToProps,
)(CartCount);

export default CartCountContainer;
